// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section_about {
    padding: 50px 0;
}
.border-radius-6 {
    border-radius: 6px !important;
    -webkit-border-radius: 6px !important;
    -moz-border-radius: 6px !important;
    -ms-border-radius: 6px !important;
    -o-border-radius: 6px !important;
    
    overflow: hidden;
}

.bg-blue {
    background-color: #005ba5 !important;
}

.bg-green {
    background-color: #379339 !important;
}

.chs-list li {
    margin-bottom: 10px;
}

`, "",{"version":3,"sources":["webpack://./src/pages/about/About.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,6BAA6B;IAC7B,qCAAqC;IACrC,kCAAkC;IAClC,iCAAiC;IACjC,gCAAgC;;IAEhC,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".section_about {\n    padding: 50px 0;\n}\n.border-radius-6 {\n    border-radius: 6px !important;\n    -webkit-border-radius: 6px !important;\n    -moz-border-radius: 6px !important;\n    -ms-border-radius: 6px !important;\n    -o-border-radius: 6px !important;\n    \n    overflow: hidden;\n}\n\n.bg-blue {\n    background-color: #005ba5 !important;\n}\n\n.bg-green {\n    background-color: #379339 !important;\n}\n\n.chs-list li {\n    margin-bottom: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
