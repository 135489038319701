// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-header h2 {
  font-size: 30px;
}

input[type="textarea"] {
  height: 60px !important;
}

.error-input p {
  color: red;
  font-size: 12px;
  margin: 0 0 12px;
}

.eye-icon-span {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: grey;
}
`, "",{"version":3,"sources":["webpack://./src/pages/auth/Register.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,2BAA2B;EAC3B,eAAe;EACf,WAAW;AACb","sourcesContent":[".login-header h2 {\n  font-size: 30px;\n}\n\ninput[type=\"textarea\"] {\n  height: 60px !important;\n}\n\n.error-input p {\n  color: red;\n  font-size: 12px;\n  margin: 0 0 12px;\n}\n\n.eye-icon-span {\n  position: absolute;\n  top: 50%;\n  right: 10px;\n  transform: translateY(-50%);\n  cursor: pointer;\n  color: grey;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
