// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.clinic-booking .cart_btn{
    border: 2px solid #20c0f3;
    background-color: #20c0f3;
    color: #20c0f3;
    display: block;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    padding: 10px 15px;
    border-radius: 4px;
}

`, "",{"version":3,"sources":["webpack://./src/pages/pharmacy/Pharmacy.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,cAAc;IACd,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,0BAA0B;IAC1B,WAAW;IACX,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":["\n.clinic-booking .cart_btn{\n    border: 2px solid #20c0f3;\n    background-color: #20c0f3;\n    color: #20c0f3;\n    display: block;\n    font-size: 14px;\n    font-weight: 500;\n    letter-spacing: 1px;\n    text-align: center;\n    text-transform: capitalize;\n    width: 100%;\n    padding: 10px 15px;\n    border-radius: 4px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
