import React from "react";
import Header from "../Header";
import Breadcrumb from "../Breadcrumb";
import Footer from "../Footer";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./TermsAndConditions.css"; // External CSS file for styling

const TermsAndConditions = () => {
  return (
    <>
      <Header />
      <Breadcrumb />

      <section className="terms-section py-5">
        <Container>
          <Row>
            <Col lg={12}>
              <h2 className="terms-title">📜 Terms & Conditions</h2>

              <Card className="terms-card">
                <Card.Body>
                  <p>
                    This document is an electronic record in terms of the{" "}
                    <strong>Information Technology Act, 2000</strong> and rules thereunder, along with amendments 
                    related to electronic records. This electronic record is generated by a computer system and 
                    does not require any physical or digital signatures.
                  </p>

                  <p>
                    These Terms & Conditions apply to the use of the website{" "}
                    <strong>Chshealthcare.in</strong> and its related mobile site and application 
                    (collectively referred to as the <strong>"Platform"</strong>).
                  </p>

                  <p>
                    The Platform is owned by{" "}
                    <strong>KRITARTH HITARTH SEWA SAMITI</strong>, registered under the Companies Act, 1956, with 
                    its office at:
                  </p>
                  <ul>
                    <li>🏢 <strong>65-Gayatri Puram, near Rama Hospital, Kursi Road, Lucknow – 226022</strong></li>
                  </ul>
                </Card.Body>
              </Card>

              <Card className="terms-card">
                <Card.Body>
                  <h4>⚖️ User Agreement</h4>
                  <ul>
                    <li>✅ By accessing or using the Platform, you agree to abide by these Terms & Conditions.</li>
                    <li>📄 You are responsible for providing accurate and complete information during registration.</li>
                    <li>🔒 You are accountable for all activities under your account.</li>
                  </ul>
                </Card.Body>
              </Card>

              <Card className="terms-card">
                <Card.Body>
                  <h4>🛠️ Services and Usage</h4>
                  <ul>
                    <li>🔍 We strive to provide accurate and updated information but do not guarantee its accuracy or completeness.</li>
                    <li>⚠️ Use of the Platform is entirely at your own risk. We are not liable for inaccuracies or errors.</li>
                    <li>🔒 Unauthorized use may result in legal action against you.</li>
                  </ul>
                </Card.Body>
              </Card>

              <Card className="terms-card">
                <Card.Body>
                  <h4>💳 Transactions and Payments</h4>
                  <ul>
                    <li>💡 You agree to pay the applicable charges for availing our services.</li>
                    <li>🔒 We are not responsible for failed transactions due to technical issues or authorization declines.</li>
                  </ul>
                </Card.Body>
              </Card>

              <Card className="terms-card">
                <Card.Body>
                  <h4>🔗 Third-Party Links</h4>
                  <ul>
                    <li>🌐 The Platform may contain links to third-party websites for convenience.</li>
                    <li>🚫 We are not responsible for the terms, privacy, or content of third-party websites.</li>
                  </ul>
                </Card.Body>
              </Card>

              <Card className="terms-card">
                <Card.Body>
                  <h4>🔒 Intellectual Property</h4>
                  <ul>
                    <li>💡 All content, design, and graphics on the Platform are proprietary.</li>
                    <li>🚫 Unauthorized reproduction or distribution is prohibited.</li>
                  </ul>
                </Card.Body>
              </Card>

              <Card className="terms-card">
                <Card.Body>
                  <h4>📌 Indemnification</h4>
                  <ul>
                    <li>🛡️ You agree to indemnify and hold harmless the Platform Owner and its affiliates from any claims or penalties due to your violation of these Terms or any third-party rights.</li>
                  </ul>
                </Card.Body>
              </Card>

              <Card className="terms-card">
                <Card.Body>
                  <h4>⚠️ Limitation of Liability</h4>
                  <p>
                    We are not liable for any loss or damage resulting from:
                  </p>
                  <ul>
                    <li>🚫 Your misuse of the Platform.</li>
                    <li>⚠️ Errors, inaccuracies, or service interruptions.</li>
                    <li>🔒 Force majeure events beyond our control.</li>
                  </ul>
                </Card.Body>
              </Card>

              <Card className="terms-card">
                <Card.Body>
                  <h4>⚖️ Jurisdiction and Governing Law</h4>
                  <p>
                    All disputes related to these Terms are governed by the laws of <strong>India</strong>.
                  </p>
                  <p>
                    🏛️ Jurisdiction lies with the courts in <strong>Lucknow, Uttar Pradesh</strong>.
                  </p>
                </Card.Body>
              </Card>

              <Card className="terms-card">
                <Card.Body>
                  <h4>📧 Contact Information</h4>
                  <p>
                    For any concerns or queries regarding these Terms & Conditions, reach out to us through the 
                    contact details provided on the Platform.
                  </p>
                </Card.Body>
              </Card>

            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </>
  );
};

export default TermsAndConditions;
