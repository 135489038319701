// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.patient-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 15px;
  }
  
  .patient-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .patient-details {
    flex: 1 1;
  }
  
  .patient-name {
    font-size: 16px;
    font-weight: bold;
  }
  
  .patient-id {
    color: #666;
    font-size: 14px;
  }
  
  .patient-age {
    color: #444;
    font-size: 14px;
  }
  
  .patient-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: 15px;
  }
  
  .patient-card-body {
    margin-top: 15px;
  }
  
  .patient-card-details {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    color: #444;
  }
  
  .patient-card-details li {
    margin-bottom: 5px;
  }
  
  .patient-card-address {
    font-size: 14px;
    color: #666;
    margin-top: 10px;
  }
  
  .appointment-info {
    margin-top: 15px;
    font-size: 14px;
  }
  
  .appointment-info p {
    margin-bottom: 5px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/doctorDashboard/DoctorDashboard.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,wCAAwC;IACxC,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,SAAO;EACT;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;IAChB,UAAU;IACV,SAAS;IACT,eAAe;IACf,WAAW;EACb;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,kBAAkB;EACpB","sourcesContent":[".patient-card {\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    background: #fff;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    overflow: hidden;\n    padding: 15px;\n  }\n  \n  .patient-card-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  \n  .patient-details {\n    flex: 1;\n  }\n  \n  .patient-name {\n    font-size: 16px;\n    font-weight: bold;\n  }\n  \n  .patient-id {\n    color: #666;\n    font-size: 14px;\n  }\n  \n  .patient-age {\n    color: #444;\n    font-size: 14px;\n  }\n  \n  .patient-img {\n    width: 80px;\n    height: 80px;\n    border-radius: 50%;\n    object-fit: cover;\n    margin-left: 15px;\n  }\n  \n  .patient-card-body {\n    margin-top: 15px;\n  }\n  \n  .patient-card-details {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n    font-size: 14px;\n    color: #444;\n  }\n  \n  .patient-card-details li {\n    margin-bottom: 5px;\n  }\n  \n  .patient-card-address {\n    font-size: 14px;\n    color: #666;\n    margin-top: 10px;\n  }\n  \n  .appointment-info {\n    margin-top: 15px;\n    font-size: 14px;\n  }\n  \n  .appointment-info p {\n    margin-bottom: 5px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
