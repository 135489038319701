import React, { useEffect } from "react";
import "./App.css";
import Routes from "./routes";
function App() {
  return (
    <>
      <div className="main-wrapper">
        <Routes />
      </div>
    </>
  );
}

export default App;
