const userRoles = [
  "Select a role",
  "Patient",
  "Doctor",
  "Pharmacy Retailers",
  "Pathology",
  "Diagnosis",
  "Ambulance",
  "Nursing",
  "Biomedical",
  "Hospital",
];


export { userRoles };
