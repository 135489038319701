export const LanguagesList = [
  { name: "Albanian", localName: "shqiptar", code: "sq" },
  { name: "Arabic", localName: "العربية", code: "ar" },
  { name: "Bengali", localName: "বাংলা", code: "bn" },
  { name: "Chinese", localName: "中文", code: "ch" },
  { name: "Dutch", localName: "Nederlandse", code: "nl" },
  { name: "English", localName: "English", code: "en" },
  { name: "French", localName: "Français", code: "fr" },
  { name: "German", localName: "German", code: "de" },
  { name: "Greek", localName: "ελληνική", code: "gr" },
  { name: "Guarani", localName: "Avañe'ẽ", code: "gu" },
  { name: "Hindi", localName: "हिंदुस्तानी", code: "hi" },
  { name: "Italian", localName: "Italiano", code: "it" },
  { name: "Korean", localName: "한국어", code: "ko" },
  { name: "Malay", localName: "Melayu", code: "ms" },
  { name: "Persian", localName: "پارسی", code: "fa" },
  { name: "Portuguese", localName: "Português", code: "pt" },
  { name: "Romanian", localName: "Română", code: "ro" },
  { name: "Russian", localName: "русский", code: "ru" },
  { name: "Serbo-Croatian", localName: "Српско-хрватски", code: "sr" },
  { name: "Spanish", localName: "Español", code: "es" },
  { name: "Swahili", localName: "Kiswahili", code: "sw" },
  { name: "Swedish", localName: "Swedish", code: "sv" },
  { name: "Tamil", localName: "தமிழ்", code: "ta" },
  { name: "Turkish", localName: "Türk", code: "tr" },
];

export const allCategories = [
  "Select Category",
  "Healthcare Devices",
  "Personal Care",
  "Nutrition and Fitness Supplements",
  "Ayurvedic Care",
  "Home Care",
  "Mother and Baby Care",
];
