// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* General Styling */
.policy-section {
  background: #f9f9f9;
  color: #333;
  line-height: 1.8;
  padding: 50px 0;
}

/* Title */
.policy-title {
  font-size: 2.5rem;
  text-align: center;
  color: #007bff;
  margin-bottom: 40px;
}

/* Card Styling */
.policy-card {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out;
  padding: 25px;
}

.policy-card:hover {
  transform: translateY(-5px);
}

.policy-card h4 {
  color: #ff5722;
  font-size: 1.8rem;
  margin-bottom: 15px;
}

.policy-card p,
.policy-card ul {
  font-size: 1.1rem;
  color: #555;
}

/* List Styling */
ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin-bottom: 10px;
  line-height: 1.6;
}

.policy-card {
  ul li::before {
    content: "✔️";
    margin-right: 10px;
    color: #28a745;
    font-weight: bold;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .policy-title {
    font-size: 2rem;
  }

  .policy-card h4 {
    font-size: 1.5rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/static/CancellationRefundPolicy.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;EACE,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,eAAe;AACjB;;AAEA,UAAU;AACV;EACE,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,mBAAmB;AACrB;;AAEA,iBAAiB;AACjB;EACE,mBAAmB;EACnB,mBAAmB;EACnB,yCAAyC;EACzC,mBAAmB;EACnB,sCAAsC;EACtC,aAAa;AACf;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;;EAEE,iBAAiB;EACjB,WAAW;AACb;;AAEA,iBAAiB;AACjB;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;IACb,kBAAkB;IAClB,cAAc;IACd,iBAAiB;EACnB;AACF;;AAEA,sBAAsB;AACtB;EACE;IACE,eAAe;EACjB;;EAEA;IACE,iBAAiB;EACnB;AACF","sourcesContent":["/* General Styling */\n.policy-section {\n  background: #f9f9f9;\n  color: #333;\n  line-height: 1.8;\n  padding: 50px 0;\n}\n\n/* Title */\n.policy-title {\n  font-size: 2.5rem;\n  text-align: center;\n  color: #007bff;\n  margin-bottom: 40px;\n}\n\n/* Card Styling */\n.policy-card {\n  background: #ffffff;\n  border-radius: 12px;\n  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);\n  margin-bottom: 20px;\n  transition: transform 0.3s ease-in-out;\n  padding: 25px;\n}\n\n.policy-card:hover {\n  transform: translateY(-5px);\n}\n\n.policy-card h4 {\n  color: #ff5722;\n  font-size: 1.8rem;\n  margin-bottom: 15px;\n}\n\n.policy-card p,\n.policy-card ul {\n  font-size: 1.1rem;\n  color: #555;\n}\n\n/* List Styling */\nul {\n  list-style-type: none;\n  padding: 0;\n}\n\nul li {\n  margin-bottom: 10px;\n  line-height: 1.6;\n}\n\n.policy-card {\n  ul li::before {\n    content: \"✔️\";\n    margin-right: 10px;\n    color: #28a745;\n    font-weight: bold;\n  }\n}\n\n/* Responsive Design */\n@media (max-width: 768px) {\n  .policy-title {\n    font-size: 2rem;\n  }\n\n  .policy-card h4 {\n    font-size: 1.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
