// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* General Styles */
.contact-section {
    background: #f9f9f9;
    min-height: 100vh;
    padding: 60px 0;
  }
  
  .contact-card {
    border: none;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
  }
  
  .contact-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
  
  /* Colorful Icons */
  .colorful-icon {
    color: #ff4d4d;
    transition: 0.3s;
  }
  
  .colorful-icon:hover {
    color: #007bff;
    transform: scale(1.1);
  }
  
  /* Medium Button with Hover Effect */
  .btn-medium {
    background: #007bff;
    color: #fff;
    font-size: 16px;
    padding: 12px 30px;
    border: none;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .btn-medium:hover {
    background: #0056b3;
    color: #fff;
    box-shadow: 0 5px 15px rgba(0, 123, 255, 0.5);
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/contact/Contact.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,gCAAgC;IAChC,yCAAyC;IACzC,mBAAmB;EACrB;;EAEA;IACE,2BAA2B;IAC3B,0CAA0C;EAC5C;;EAEA,mBAAmB;EACnB;IACE,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA,oCAAoC;EACpC;IACE,mBAAmB;IACnB,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,mBAAmB;IACnB,WAAW;IACX,6CAA6C;EAC/C","sourcesContent":["/* General Styles */\n.contact-section {\n    background: #f9f9f9;\n    min-height: 100vh;\n    padding: 60px 0;\n  }\n  \n  .contact-card {\n    border: none;\n    transition: all 0.3s ease-in-out;\n    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n    border-radius: 12px;\n  }\n  \n  .contact-card:hover {\n    transform: translateY(-4px);\n    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);\n  }\n  \n  /* Colorful Icons */\n  .colorful-icon {\n    color: #ff4d4d;\n    transition: 0.3s;\n  }\n  \n  .colorful-icon:hover {\n    color: #007bff;\n    transform: scale(1.1);\n  }\n  \n  /* Medium Button with Hover Effect */\n  .btn-medium {\n    background: #007bff;\n    color: #fff;\n    font-size: 16px;\n    padding: 12px 30px;\n    border: none;\n    border-radius: 8px;\n    transition: all 0.3s ease;\n  }\n  \n  .btn-medium:hover {\n    background: #0056b3;\n    color: #fff;\n    box-shadow: 0 5px 15px rgba(0, 123, 255, 0.5);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
